import React,{ useState,useEffect} from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import {Spinner,Table, Pagination,Col,Row,Card,Badge, Dropdown, Tab, Nav } from "react-bootstrap";

import { useDispatch,useSelector } from "react-redux";

import axiosInstance from '../../../services/AxiosInstance';

import {
	getProfile,
 } from '../../../store/actions/AuthActions';

// import Ext from "../../layouts/Ext";




const Home = () => {

   const dispatch = useDispatch();

   const [statistics, setStatistics] = useState(null);
   const profile = useSelector((state) => state.auth.profile);
   

   useEffect(() => {
		dispatch(getProfile());
      loadStatistics();
	  }, [dispatch]);



   async function loadStatistics(){
		
      try {
         
         const { data } = await axiosInstance.get(
              "agent/statistics"
         );
   

     console.log("statistics data - "+JSON.stringify(data));
   
      if(data.status === false){
         
      }else{
         setStatistics(data);
      }
      
      }catch (error) {
         
   
      }
   
     }
   


   function SampleNextArrow(props) {
      const { onClick } = props;
      return (
         <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
            <i className="fa fa-caret-right" />
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
         <div
            className="owl-prev disabled"
            onClick={onClick}
            style={{ zIndex: 99 }}
         >
            <i className="fa fa-caret-left" />
         </div>
      );
   }

   const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1599,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 990,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   return (
      <>
         <div className="row">
            <div className="col-xl-3 col-md-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                    
                     <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.members:0}</h2> 
                        <span className="circle-1 bg-primary mt-4" />
                     </div>
                     
                     <span className="fs-14">Total Members</span>

                  </div>
               </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                    
                     <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.subAgents:0}</h2> 
                        <span className="circle-1 bg-primary mt-4" />
                     </div>
                     
                     <span className="fs-14">Total Sub Agent</span>

                  </div>
               </div>
            </div>

            <div className="col-xl-3 col-md-6 col-sm-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.counters??0:0}</h2> 
                        <span className="circle-1 bg-success mt-4" />
                     </div>
                     
                     <span className="fs-14">Available Counters</span>
                  </div>
               </div>
            </div>

            <div className="col-xl-3 col-md-6 col-sm-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.wallet??0:0}</h2> 
                        <span className="circle-1 bg-success mt-4" />
                     </div>
                     
                     <span className="fs-14">Wallet Balance</span>
                  </div>
               </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6">
               <div className="card bordered-card">
               <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.memberCounters:0}</h2> 
                        <span className="circle-1 bg-warning mt-4" />
                     </div>
                     
                     <span className="fs-14">Members Counters</span>
                  </div>
               </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6">
               <div className="card bordered-card">
               <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.used:0}</h2> 
                        <span className="circle-1 bg-danger mt-4" />
                     </div>
                     
                     <span className="fs-14">Used Counters</span>
                  </div>
               </div>
            </div>
            

          

         
         </div>

         <div className="row">
         <div className="col-12">
         <div className="card">
            <div className="card-header">
               <h4 className="card-title">Commission Chart</h4>
            </div>
            <div className="card-body">
               {profile ? [<Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                             <td>Agent Level</td>
                             <td>Wallet Commission</td>
                             <td>Counter Commission</td>
                             <td>Total Commission</td>
                           </tr>
                        </thead>
                        <tbody>

                           {profile.agentLevel == 3 ?(
                          <tr>
                             <td>Level 3</td>
                             <td>10%</td>
                             <td>5%</td>
                             <td>15%</td>
                          </tr>):(<></>)}

                          {profile.agentLevel == 2 ?(
                          [
                           <tr>
                           <td>Level 2</td>
                           <td>13%</td>
                           <td>7%</td>
                           <td>20%</td>
                           </tr>,
                           <tr>
                             <td>Level 3</td>
                             <td>7%</td>
                             <td>3%</td>
                             <td>10%</td>
                          </tr>]
                          
                          ):(<></>)}

                        {profile.agentLevel == 1 ?(
                          [

                           <tr>
                           <td>Level 1</td>
                           <td>20%</td>
                           <td>10%</td>
                           <td>30%</td>
                           </tr>,

                           <tr>
                           <td>Level 2</td>
                           <td>7%</td>
                           <td>3%</td>
                           <td>10%</td>
                        </tr>,

                           <tr>
                           <td>Level 3</td>
                           <td>3%</td>
                           <td>2%</td>
                           <td>5%</td>
                        </tr>,
                        

                          
                          
                          
                          ]
                          
                          ):(<></>)}

                        </tbody>
                       
                     </table>

                     
                  </div>
               </Table>,
               <p classname="mt-4">All Agent get 30% (20% wallet &amp; 10% counter) back on own order.</p>]
                     
               :<div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>}
            </div>
         </div>
      </div>
         </div>
      </>
   );
};

export default Home;
